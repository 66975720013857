import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  forwardRef,
  useMemo,
  useState,
} from 'react'
import { UseSiteSearchData } from 'react-datocms/use-site-search'

import { ScrollToggle } from '@/features/common/'
import { useElementHeight } from '@/hooks/useElementRect'
import { bezier, mq } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import { useSearchContext } from '../../contexts/searchContext'
import { PageResultData, SearchResult } from './SearchResult'
import { SearchingInterstitial } from './SearchingInterstitial'

interface Props extends ComponentPropsWithoutRef<'section'> {
  data?: UseSiteSearchData
  isOpen: boolean
}

export const SearchResults = forwardRef<HTMLElement, Props>(
  ({ data, isOpen, ...props }, ref): JSX.Element => {
    const filteredResults = useMemo(() => {
      return data?.pageResults.reduce((acc, val) => {
        if (val.raw.attributes.score > 0.6) {
          acc.push(val)
        }
        return acc
      }, [] as PageResultData[])
    }, [data])

    const [resultsRef, setResultsRef] = useState<HTMLElement | null>(
      null
    )
    const resultsHeight = useElementHeight(resultsRef)

    const { isSearching, isEmpty } = useSearchContext()

    const styles = {
      section: css`
        grid-area: 1 / 1 / 2 / 2;
        align-self: flex-start;
        display: grid;
        max-height: calc(100dvh - var(--nav-height-flex));
        margin-top: var(--nav-height-flex);
        background: #fff;
        box-sizing: border-box;
        --border-width: 1.5rem;
        border-width: var(--border-width);
        border-top-width: 0;
        border-color: ${colors.red};
        border-style: solid;
        padding: var(--row-54) var(--margin);
        overflow: auto;
        transform: translate3d(0, -100%, 0);
        opacity: 0;
        transition:
          opacity 300ms ${bezier.easeOut} 300ms,
          transform 500ms ${bezier.easeOut};
        ${isOpen &&
        !isEmpty &&
        css`
          pointer-events: all;
          z-index: 2;
          opacity: 1;
          transform: translate3d(0, 0, 0);
          transition-duration: 400ms, 800ms;
          transition-delay: 0ms;
        `}
        ${mq().s} {
          --border-width: 0.75rem;
        }
      `,
      results: css`
        grid-area: 1 / 1 / 2 / 2;
        align-self: center;
        opacity: 0;
        height: ${resultsHeight}px;
        height: ${isSearching && 0};
        overflow: hidden;
        transition:
          opacity 300ms ease,
          height 500ms ease;
        padding: 1rem;
        margin: -1rem;
        ${!isSearching &&
        !isEmpty &&
        filteredResults?.length !== 0 &&
        css`
          opacity: 1;
          transition-delay: 150ms, 0ms;
        `}
      `,
      resultsInner: css`
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 3rem;
        ${mq().m} {
          grid-template-columns: repeat(2, 1fr);
        }
        ${mq().s} {
          grid-template-columns: 1fr;
          gap: 1.5rem;
        }
      `,
      message: css`
        grid-area: 1 / 1 / 2 / 2;
        pointer-events: none;
        height: 100%;
        display: grid;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        > * {
          grid-area: 1 / 1 / 2 / 2;
          font-size: var(--fs-60);
          color: ${colors.gray65};
          text-align: center;
        }
      `,
      noResults: css`
        opacity: 0;
        transition: opacity 300ms ease;
        pointer-events: none;
        ${!isSearching &&
        !isEmpty &&
        filteredResults?.length === 0 &&
        css`
          opacity: 1;
          transition-delay: 150ms;
        `}
      `,
      enterQuery: css`
        opacity: 0;
        transition: opacity 300ms ease;
        pointer-events: none;
        ${isEmpty &&
        css`
          opacity: 1;
        `}
      `,
    }

    return (
      <section
        css={styles.section}
        ref={ref}
        {...props}
      >
        {isOpen && <ScrollToggle />}
        <div css={styles.results}>
          <div
            css={styles.resultsInner}
            ref={node => setResultsRef(node)}
          >
            {filteredResults?.map((result, i) => (
              <SearchResult
                key={i}
                data={result}
                tabIndex={isOpen ? 0 : -1}
              />
            ))}
          </div>
        </div>
        <div css={styles.message}>
          <SearchingInterstitial isSearching={isSearching} />
          <h2 css={styles.noResults}>No results found.</h2>
        </div>
      </section>
    )
  }
)
SearchResults.displayName = 'SearchResults'
